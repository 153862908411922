import React, { useState } from "react";
import styled from "styled-components";

import { Ideas } from "./components/Ideas";

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const Marquee = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const shortId = (length = 5) =>
  Math.random()
    .toString(36)
    .substr(-length);

export const App = () => {
  const [keys, setKeys] = useState([shortId()]);

  const unmount = key => setKeys(prevKeys => prevKeys.filter(k => k !== key));

  const append = () => {
    if (keys.length >= 2) return;
    setKeys(prevKeys => [...prevKeys, shortId()]);
  };

  return (
    <Page>
      <Marquee>
        {keys.map(key => {
          return (
            <Ideas key={key} onRemove={() => unmount(key)} onNext={append} />
          );
        })}
      </Marquee>
    </Page>
  );
};

export default App;
