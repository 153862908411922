const ENDPOINT = "https://atlas.auspic.es/graphql";

const QUERY = amount => `{
  user(id: "damon") {
    collection(id: "ideas") {
      ideas: sample(amount: ${amount}) {
        id
        entity {
          ... on Text {
            id
            body
          }
        }
      }
    }
  }
}`;

export const getIdeas = (amount = 10) =>
  fetch(ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({ query: QUERY(amount) })
  })
    .then(res => res.json())
    .then(({ data: { user: { collection: { ideas } } } }) => {
      return ideas;
    });
