import React, { memo } from "react";
import styled from "styled-components";
import chanceFont from "chance-font";

import { randomColor } from "../../lib/randomColor";

const Container = styled.a`
  display: inline-block;
  padding: 0 0.5em;
  font-size: 1rem;
  font-family: ${() => chanceFont()};
  color: ${() => randomColor()};
  text-decoration: none;
`;

export const Idea = memo(({ idea }) => {
  return (
    <Container
      href={`https://www.auspic.es/damon/x/${idea.id}`}
      target="_blank"
    >
      {idea.entity.body}
    </Container>
  );
});
