import React, { useReducer, useEffect, useRef } from "react";
import styled from "styled-components";

import { getIdeas } from "../../lib/getIdeas";
import { useAnimationFrame } from "../../lib/useAnimationFrame";
import { Idea } from "../Idea";

const IDEAS_AMOUNT = 10;
const MOVE_AMOUNT_DIVISOR = 12;
const ROOT_FONT_SIZE = parseInt(
  window.getComputedStyle(document.documentElement).fontSize,
  10
);
const MOVE_AMOUNT = ROOT_FONT_SIZE / MOVE_AMOUNT_DIVISOR;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  white-space: nowrap;
  opacity: 0;

  ${props =>
    props.running &&
    `
    opacity: 1;
  `}
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "IDEAS":
      return {
        ...state,
        ideas: action.ideas
      };
    case "SETUP":
      return {
        ...state,
        offset: action.offset,
        limit: action.limit,
        threshold: action.threshold,
        running: true
      };
    case "TICK":
      return {
        ...state,
        offset: state.offset + MOVE_AMOUNT
      };
    case "THRESHOLD":
      return {
        ...state,
        next: true
      };
    default:
      throw new Error("Invalid action");
  }
};

export const Ideas = ({ onRemove, onNext }) => {
  const el = useRef(null);

  const [state, dispatch] = useReducer(reducer, {
    running: false,
    ideas: [],
    offset: 0,
    threshold: 0,
    limit: Infinity,
    next: false
  });

  const { running, ideas, offset, limit, threshold, next } = state;

  useAnimationFrame(() => {
    if (!running) return;

    if (offset >= limit) {
      return onRemove();
    }

    if (!next && offset >= threshold) {
      dispatch({ type: "THRESHOLD" });
    }

    dispatch({ type: "TICK" });
  });

  useEffect(() => {
    getIdeas(IDEAS_AMOUNT).then(ideas => {
      dispatch({
        type: "IDEAS",
        ideas
      });
    });
  }, []);

  useEffect(() => {
    if (ideas.length === 0) return;

    const { width } = el.current.getBoundingClientRect();

    dispatch({
      type: "SETUP",
      offset: -window.innerWidth,
      limit: width,
      threshold: width - window.innerWidth
    });
  }, [ideas]);

  useEffect(() => {
    next && onNext();
  }, [next, onNext]);

  return (
    <Container
      running={running}
      ref={el}
      style={{ transform: `translate(${-offset}px, -50%)` }}
    >
      {ideas.map(idea => (
        <Idea key={idea.id} idea={idea} />
      ))}
    </Container>
  );
};
